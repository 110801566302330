/* eslint-disable @next/next/no-html-link-for-pages */
import SocialAuthButtons from 'shared/components/auth/SocialAuthButtons'
import Button from 'shared/components/button/Button'
import { PATTERN } from 'configs/validation'
import GCaptchaProvider, { useGCaptchaVerify } from 'contexts/GCaptchaProvider'
import useAuth from 'hooks/authentication/useAuth'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { trackSegmentCustomEvent } from 'shared/services/analytics/segment'
import { UserProfile } from 'shared/types/V3/User'
import { useEffectOnce } from 'usehooks-ts'
import useAuthType from 'views/authentication/hooks/useAuthType'
import { authenticateWithApple, authenticateWithGoogle } from 'views/authentication/services/authenticateWithProvider'
import quickSignUp from 'views/authentication/services/quickSignUp'
import loginUser from 'views/authentication/services/loginUser'
import swapCohartToken from 'views/authentication/services/swapCohartToken'
import debounce from 'lodash.debounce'
import StyledAuthInput from './StyledAuthInput'
import { AuthError, AuthErrorCodes } from 'firebase/auth'
import AttentionIcon from 'shared/icons/AttentionIcon'
import { AuthType } from 'shared/types/Auth'
import { useRouter } from 'next/router'
import { WEB_BASE_URL } from 'configs'
import checkExistedUser from 'shared/services/checkExistedUser'
import { sendHubspotSignUpFormAPI, setUpSegmentTrackingForSignUp } from '../util'
import CohartLogo from './Logo'
import { SignUpAsRole } from 'shared/types/App'
import QuickSignUpResend from './QuickSignUpResend'
import InputErrorText from 'shared/components/input/InputErrorText'

type QuickSignupInputs = {
  email: string
}

type Props = {
  asRole?: SignUpAsRole
  onSuccess?: () => void
  marketingMessage?: ReactNode
  customizedSignUpTitle?: ReactNode
  onLogoClick?: () => void
  username?: string
}

const QuickSignup = (props: Props) => {
  const { asRole, onLogoClick, username } = props
  const { isCaptchaReady, handleReCaptchaVerify } = useGCaptchaVerify()
  const router = useRouter()
  const { setSignUpAsRole } = useAuth()
  const [authType, setAuthType] = useAuthType()
  const [isRequesting, setIsRequesting] = useState(false)
  const [signUpError, setSignUpError] = useState<string | null>(null)
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const [resendModal, setResendModal] = useState({ opened: false, email: '', redirectUrl: '' })

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<QuickSignupInputs>({
    defaultValues: {
      email: '',
    },
  })

  const email = watch('email')
  const enableSubmitButton = Boolean(email)

  const handleAuthenticateError = useCallback((e: unknown) => {
    let errorMessage = 'Cannot sign up user'
    if (typeof e === 'string') {
      errorMessage = e
    }

    const code = (e as AuthError).code
    if (code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
      errorMessage = 'User closed the authentication popup'
    }

    setSignUpError(errorMessage)
    console.error('Sign up error:', e)
  }, [])

  const handleAuthenticateSuccess = useCallback(
    async (user: UserProfile, signUpMethod: AuthType) => {
      setUpSegmentTrackingForSignUp({
        userId: user.id,
        signUpMethod,
        asRole,
        email: user.email,
        username,
        firstName: user.firstName,
        createdAt: user.createdAt,
      })
      setSignUpAsRole(undefined)
      setResendModal((prev) => ({ ...prev, opened: true }))
    },
    [asRole, setSignUpAsRole, username],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitSignUpForm = useCallback(
    debounce(async (data: QuickSignupInputs) => {
      if (isRequesting) return // auth is in progress

      await handleReCaptchaVerify('signup_user')

      setIsRequesting(true)

      try {
        // Add this check, since we don't want to send the Hubspot sign up FormAPI if email is existed,
        // and the check is happened in /sign-up-new
        const existedUser = await checkExistedUser(data.email)
        if (existedUser) {
          setError('email', {
            message: (
              <>
                Email address already in use.
                <span className="mr-1" />
                <a
                  href={`/login?email=${encodeURIComponent(data.email)}`}
                  className="font-semibold text-primary hover:underline"
                >
                  Sign in with this email instead
                </a>
              </>
            ) as unknown as string,
          })
          setIsRequesting(false)
          return
        }

        // send the Hubspot sign up Form API, for tracking purpose
        await sendHubspotSignUpFormAPI(
          data.email, // temporary using email for `firstName`
          data.email,
          `https://${WEB_BASE_URL}${router.asPath}`,
        )

        // quick sign up user on the backend
        const user = await quickSignUp({
          email: data.email,
          username,
          role: asRole,
          redirectUrl: window.location.href,
        })
        setResendModal({
          opened: false,
          email: data.email,
          redirectUrl: window.location.href,
        })

        // success callback
        await handleAuthenticateSuccess(user, authType)
      } catch (error) {
        const errorMessage = typeof error === 'string' ? error : 'Cannot sign up user'
        setError('email', { message: errorMessage })
      } finally {
        setIsRequesting(false)
      }
    }, 1000),
    [isRequesting, handleReCaptchaVerify],
  )

  const signUpWithGoogle = async () => {
    if (isRequesting) return // auth is in progress

    setAuthType('google')
    setIsRequesting(true)

    try {
      const userCredential = await authenticateWithGoogle(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback

      // send the Hubspot sign up Form API, for tracking purpose
      await sendHubspotSignUpFormAPI(
        userCredential.user.displayName as string,
        userCredential.user.email as string,
        `https://${WEB_BASE_URL}${router.asPath}`,
      )

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential, { role: 'collector' })

      // mark user as logged in
      await loginUser(user)

      // success callback
      await handleAuthenticateSuccess(user, 'google')
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  const signUpWithApple = async () => {
    if (isRequesting) return // auth is in progress

    setAuthType('apple')
    setIsRequesting(true)
    try {
      const userCredential = await authenticateWithApple(isMobileScreen)
      if (!userCredential) return // the sign-up process will be handled by the redirect callback

      // send the Hubspot sign up Form API, for tracking purpose
      await sendHubspotSignUpFormAPI(
        userCredential.user.displayName as string,
        userCredential.user.email as string,
        `https://${WEB_BASE_URL}${router.asPath}`,
      )

      // swap cohart token (stored in cookie) by firebase credential
      const { user } = await swapCohartToken(userCredential, { role: 'collector' })
      // mark user as logged in
      await loginUser(user)

      // success callback
      await handleAuthenticateSuccess(user, 'apple')
    } catch (e) {
      handleAuthenticateError(e)
    } finally {
      setIsRequesting(false)
    }
  }

  useEffectOnce(() => {
    trackSegmentCustomEvent('user_sign_up_started')
  })

  const renderMessage = useMemo(() => {
    if (signUpError)
      return (
        <div className="flex items-center gap-1 text-xs normal-case">
          <div className="w-3">
            <AttentionIcon color="#FF4337" className="!size-3" />
          </div>
          <span className="text-[#FF4337]">&nbsp;{signUpError}</span>
        </div>
      )
    return null
  }, [signUpError])

  if (resendModal.opened) return <QuickSignUpResend email={resendModal.email} redirectUrl={resendModal.redirectUrl} />
  return (
    <>
      <div className="box-border h-full w-[80vw] space-y-3 font-inter text-[#191414] md:w-[404px] lg:space-y-6">
        {/* title */}
        <h2 className="text-medium text-center font-monument-grotes text-xl leading-none lg:text-2xl">
          Sign Up to Continue
        </h2>

        {/* sign up with social account buttons */}
        <SocialAuthButtons
          onGoogleButtonClick={signUpWithGoogle}
          onAppleButtonClick={signUpWithApple}
          isGoogleButtonLoading={isRequesting && authType === 'google'}
          isTwitterButtonLoading={isRequesting && authType === 'twitter'}
          isAppleButtonLoading={isRequesting && authType === 'apple'}
        />

        <div className="flex w-full items-center opacity-50">
          <div className="h-[1px] grow bg-[#191414]" />
          <div className="mx-5 text-[12px] font-semibold uppercase text-[#191414]">OR</div>
          <div className="h-[1px] grow bg-[#191414]" />
        </div>
        <p className="text-center text-[16px] text-[#191414]">
          Enter your email address to create your account. <br />
          It’s fast, free, and secure!
        </p>

        {/* sign-up form */}
        <form onSubmit={handleSubmit(submitSignUpForm)} className="space-y-6">
          <div className="w-full space-y-3">
            {/* Email Field */}
            <StyledAuthInput
              placeholder="Email address"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Email is required',
                },
                maxLength: { value: 254, message: 'Email must be less than 254 characters' },
                pattern: {
                  value: PATTERN.email,
                  message: 'Please enter a valid email',
                },
              })}
            />

            {/* show error message */}
            <InputErrorText message={errors.email?.message} />
          </div>

          {/* Referral Code Checkbox */}
          {renderMessage}

          <Button
            type="submit"
            name="Continue"
            wrapperClass="w-full rounded-[32px] !p-3 lg:!p-4 border border-black/10 bg-primary"
            contentClass="!text-sm lg:!text-base !font-semibold text-white !uppercase"
            loadingClassName="!border-b-white !border-r-white !border-t-white"
            disabled={!isCaptchaReady || isRequesting || !enableSubmitButton}
            loading={isRequesting && authType === 'password'}
          />
        </form>
        <div className="space-y-2 lg:space-y-3">
          {/* switch to sign-in option */}
          <div className="flex w-full justify-center text-sm">
            <span>
              Already have an account? {/* use <a> for server navigation */}
              <a href="/login" className="font-semibold text-primary hover:underline">
                Sign in
              </a>
            </span>
          </div>

          {/* term and condition */}
          <div className="w-full text-center text-xs text-[#898788]">
            By signing up, you agree to our&nbsp;
            <a className="font-medium text-primary hover:underline" href="/terms-of-service" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>

        {/* logo */}
        <CohartLogo onClick={onLogoClick} />
      </div>
    </>
  )
}

const QuickSignupWithGCaptcha = (props: Props) => {
  return (
    <GCaptchaProvider>
      <QuickSignup {...props} />
    </GCaptchaProvider>
  )
}

QuickSignupWithGCaptcha.displayName = 'QuickSignup'
export default QuickSignupWithGCaptcha
